import type { Jsonify } from "type-fest";

import type {
  IntegrationMeta,
  IntegrationMetaWithSupportedDataTypes,
} from "@wind/contentful/models";
import type { Integration } from "@wind/integration-core/models";

import Api from "./Api.js";

const ROOT_PATH = "/integrations";

/**
 * API for interacting with integration metadata.
 *
 * @author @jchaiken1
 */
class PublicIntegrationsApi extends Api {
  static forLoaderRequest(request: Request): PublicIntegrationsApi {
    return new PublicIntegrationsApi(Api.configForLoaderRequest(request));
  }

  static forActionRequest(request: Request): PublicIntegrationsApi {
    return new PublicIntegrationsApi(Api.configForActionRequest(request));
  }

  getIntegration(integrationId: string): Promise<Jsonify<{ integration: Integration }>> {
    return this.get({
      path: this.publicPath(`${ROOT_PATH}/${integrationId}`),
    });
  }

  /**
   * Get metadata for all integrations
   */
  getAllIntegrationMeta(
    coreInfoOnly?: boolean
  ): Promise<Jsonify<{ integrations: IntegrationMeta[] }>> {
    return this.get({
      path: this.publicPath(`${ROOT_PATH}/meta`),
      query: coreInfoOnly ? { coreInfoOnly: "true" } : undefined,
    });
  }

  /**
   * Get metadata for a specific integration
   *
   * @param integrationId - The ID of the integration to get metadata for
   */
  getIntegrationMeta(
    integrationId: string
  ): Promise<Jsonify<{ integration: IntegrationMetaWithSupportedDataTypes | null }>> {
    return this.get({
      path: this.publicPath(`${ROOT_PATH}/meta/${integrationId}`),
    });
  }
}

export default PublicIntegrationsApi;
